<template>
    <div>
        <div class="relative border border-default rounded-md">
            <template v-if="type === 'textarea'">
                <textarea v-model="input" v-bind="attributes" :type="type" rows="6" @input="handleInput" />
            </template>

            <template v-if="type === 'amount'">
                <div>
                    <div class="relative rounded-md shadow-sm">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span class="text-gray-500 sm:text-sm">$</span>
                        </div>

                        <input v-model="input" v-bind="attributes" class="pl-7 pr-12" placeholder="0.00" type="text" @input="handleInput">

                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span id="price-currency" class="text-gray-500 sm:text-sm">USD</span>
                        </div>
                    </div>
                </div>
            </template>

            <template v-else>
                <input v-model="input" v-bind="attributes" :type="type" @input="handleInput">
            </template>

            <div v-if="readonly" v-tooltip.right="'Read Only'" class="absolute h-5 inset-y-0 right-0 pr-3 pt-1 top-1 flex items-start text-gray-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                </svg>
            </div>
        </div>

        <template v-if="errors[name] && errors[name].length">
            <div class="mt-1">
                <template v-for="(errorMessage, index) in errors[name]">
                    <v-text :key="'error_message_' + index" type="error">
                        {{ errorMessage }}
                    </v-text>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import FormMixin from "./form-mixin";

export default {
    mixins: [
        FormMixin,
    ],
    props: {
        type: {
            type: String,
            required: false,
            default: () => "text",
        },
    },
    data() {
        return {
            /**
             * The base classes for the component.
             *
             * @var String
             */
            baseClasses: "text-sm rounded-md text-gray-400 placeholder-gray-400 flex-1 block w-full max-w-full disabled:opacity-75 disabled:cursor-not-allowed bg-black bg-opacity-90 border-0 rounded-sm",

            /**
             * The available component states.
             *
             * @var Object
             */
            states: {
                /**
                 * The classes for when the component is in a default state.
                 */
                default: "focus:ring-2 focus:ring-primary-600",

                /**
                 * The classes for when the component is readonly.
                 */
                readonly: "text-gray-400",

                /**
                 * The classes for when the component has errors.
                 */
                error: "ring-2 focus:ring-2 ring-red-500 focus:ring-red-500",
            },
        };
    },
    computed: {
        classes() {
            return `v-form-input ${this.baseClasses} ${this.states[this.state]}`;
        },
        attributes() {
            return {
                class: this.classes,
                readonly: this.readonly,
                ...this.$attrs,
            };
        },
    },
};
</script>
